export default class Camera {
    static videoDevices = [];
    static async support () {
        if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
            // window.console.log('supportCamera:', navigator)
            return false
        }
        let support = false
        var devices = await navigator.mediaDevices.enumerateDevices()
        devices.forEach(d => {
            if (d.kind === 'videoinput') {
                this.videoDevices.push(d)
                support = true
            }
        })
        window.console.log(devices)
        return this.videoDevices.length > 0
    }

    getUserMedia (constraints, success, error) {
        if (navigator.mediaDevices === undefined) {
          navigator.mediaDevices = {}
        }
        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = constraints => {
            var getUserMedia =
              navigator.webkitGetUserMedia ||
              navigator.mozGetUserMedia ||
              navigator.getUserMedia
            if (!getUserMedia) {
              return Promise.reject(
                new Error('getUserMedia is not implemented in this browser')
              )
            }
            return new Promise(function (resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject)
            })
          }
        }
    }
}
