import fetch from '@/utils/fetch'

/**
 * api抽象类
 */
export default class Api {
  servicePath = '/h5/user';

  modulePath = '';

  constructor (modulePath) {
    this.modulePath = modulePath
  }

  request (config) {
    return fetch(config)
  }

  /**
   * 获取分页数据
   * @param {*} 分页请求数据
   */
  getPaged ({
    pageInfo,
    condition
  }, config) {
    return this.request({
      ...config,
      url: `${this.servicePath}/${this.modulePath}/page`,
      method: 'post',
      pageInfo,
      data: condition
    })
  }

  /**
   * 获取查询数据
   * @param {*} 请求参数
   * @param {*} 指定api
   */
  getQuery ({
    pageInfo,
    condition
  }, query = 'query', config) {
    return this.request({
      ...config,
      url: `${this.servicePath}/${this.modulePath}/${query}`,
      method: 'post',
      pageInfo,
      data: condition
    })
  }

  /**
   * 根据Id获取详情
   * @param {*} 唯一id
   */
  getById ({
    id
  }, config) {
    return this.request({
      ...config,
      url: `${this.servicePath}/${this.modulePath}/${id}`,
      method: 'get'
    })
  }

  /**
   * 创建新对象
   * @param {*} 对象内容
   */
  insert ({
    form
  }, config) {
    return this.request({
      ...config,
      url: `${this.servicePath}/${this.modulePath}`,
      method: 'post',
      data: form
    })
  }

  /**
   * 更新已存在的对象
   * @param {*} 对象内容
   */
  update ({
    form
  }, config) {
    return this.request({
      ...config,
      url: `${this.servicePath}/${this.modulePath}`,
      method: 'put',
      data: form
    })
  }

  /**
   * 删除对象
   * @param {*} 唯一id
   */
  delete ({
    id
  }, config) {
    return this.request({
      ...config,
      url: `${this.servicePath}/${this.modulePath}/${id}`,
      method: 'delete'
    })
  }
}
