import Api from './api'

class WechatApi extends Api {
  /**
   * 获取授权地址
   * @param{*}
   */
  getAuthorizeUrl (data) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/authorizeUrl/snsapi_base`,
      method: 'post',
      data,
      dataType: 'form'
    })
  }

  /**
   * 获取jsconfig
   * @param{*}
   */
  getConfig (data) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/authorize`,
      method: 'post',
      data,
      dataType: 'form'
    })
  }

  getCode (url) {
    return this.request({
      url: url,
      method: 'get'
    })
  }

  /**
   * 获取accesstoken
   * @param{*}
   */
  getAccessToken (code) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/accesstoken/${code}`,
      method: 'get'
    })
  }

  /**
   * 获取用户信息
   * @param{*}
   */
  getUserInfo (openId) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/userInfo/${openId}`,
      method: 'get'
    })
  }
}

export default new WechatApi('wx')
